@font-face {
  font-family: "July Seventh";
  src: url('/fonts/secondary.otf');
}
@font-face {
  font-family: "PT Sans Regular";
  src: url('/fonts/PTSans-Regular.ttf');
}
@font-face {
  font-family: "PT Sans Narrow";
  src: url('/fonts/PT_Sans-Narrow-Web-Regular.ttf');
}

html, body, #root {
  /*min-height: 100vh;*/
  /*overflow: hidden;*/
  margin: 0;
  font-family: "PT Sans Regular", "Roboto", "Helvetica", "Arial", sans-serif;
}

html {
  background: #f9f9f9;
  overflow-x: hidden;
}

html {
  font-size: 13.5px;
}

@media (min-width: 312px) {html {
  font-size: 16px
}
}

@media (min-width: 624px) {html {
  font-size: 16px
}
}

@media (min-width: 936px) {html {
  font-size: 16px
}
}

@media (min-width: 1248px) {
  html {
    font-size: 16px
  }
}

#root {
  /*overflow-x: hidden;*/
}

body {
  -webkit-font-smoothing: antialiased;
  /*overflow: hidden;*/
}

.flex-empty-space {
  flex-grow: 1;
}

a {
  color: #ab1945;
}

.color-primary {
  color: #ab1945;
}

.link-style {
  text-decoration: underline;
  cursor: pointer;
}

.font-primary {
  font-family: "PT Sans Regular"!important;
}

.font-primary-narrow {
  font-family: "PT Sans Narrow"!important;
}

.font-secondary {
  font-family: "July Seventh"!important;
}

.font-bold {
  font-weight: bold;
}

@keyframes animateDialog {
  0% {
    opacity: 0;
    transform: translateY(-80px);
  }
  50% {
    opacity: 0;
    transform: translateY(-80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.MuiPaper-root:not(.MuiMenu-paper):not(.no-slide) {
  border-radius: 0!important;
  animation: 0.4s animateDialog;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.font-md {
  font-size: 1.2rem!important;
}

.font-sm {
  font-size: 0.9rem!important;
}

.font-xs {
  font-size: 0.85rem!important;
}

.info-box {
  padding: 15px;
  font-size: 0.9rem;
  background-color: #fff8e7;
  border-radius: 3px;
}

.info-box.info-box--big {
  font-style: italic;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

p {
  font-size: 1rem;
}

.snackbar-ok.snackbar-ok.snackbar-ok {
    background: #9b4461;
    font-size: 1rem;
    border-radius: 0;
}

.form-group {
    margin-bottom: 1rem;
}

.fill-space {
  flex-grow: 1;
}

.mobile-phone-only {
  display: none;
}

ul {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-decoration:hover {
  text-decoration: underline;
}

p.big {
  font-size: 1.15rem;
}

img.max-100 {
  max-width: 100%;
}

.mt-0 {
  margin-top: 0;
}

.color-red {
  color: red!important;
}

@media (max-width: 576px) {
  .mobile-phone-only {
    display: inline!important;
  }

  .desktop-only {
    display: none!important;
  }
}
